export class Payment {
  amount: number;
  currency: string;
  sourceToken: string;


  constructor(obj?) {
    if (obj) {
        this.amount = obj.amount;
        this.currency = obj.currency;
        this.sourceToken = obj.sourceToken;
    }
  }
}