export class Product {
  id: number = -1;
  name: string = "";
  description: string = "";
  price: number = 0.00;
  image_url: string = "";
  details: any = {};
  details_list: any[] = [];
  inventory_id: string = "";

  constructor(obj?, inventory_id?) {
    if (obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.description = obj.description;
        this.price = obj.price;
        this.image_url = obj.image_url;
        this.details = obj.details;
        this.details_list = obj.details_list;
        this.inventory_id = obj.inventory_id || null;
    }

    if (inventory_id) {
      this.inventory_id = inventory_id;
    }
  }
}