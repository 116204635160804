import { Component } from '@angular/core';
import { Location } from "@angular/common";
import { Events, NavController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AlertService } from 'ng-etg-base';

import { environment } from '@environments/environment';
import { ThemeService } from '@services/theme.service';
import { SessionService } from '@services/session.service';
import { PaymentService } from '@services/payment.service';
import { Product } from '@models/product.model';
import { Session } from '@models/session.model';
import { Payment } from '../../_models/payment.model';

declare var Stripe;

@Component({
  selector: 'nfc-checkout',
  templateUrl: 'checkout.page.html',
  styleUrls: ['checkout.page.scss'],
})
export class CheckoutPage {

  public loading: boolean = false;
  public processing: boolean = false;

  private stripe = Stripe(environment.stripePublishableKey);
  private stripeCard: any;
  public canUseMobilePay: boolean = true;

  public items: Product[] = [];
  public subtotal: number = 0;
  public tax: number = 0;
  public total: number = 0;

  constructor(
    private events: Events,
    private navController: NavController,
    private router: Router,
    private location: Location,
    private toastController: ToastController,
    public theme: ThemeService,
    private sessionService: SessionService,
    private paymentService: PaymentService,
    private alertService: AlertService
  ) { }

  ionViewWillEnter() {
    this.loading = true;
    
    var session: Session = this.sessionService.loadSession();

    if (session != null) {
      this.theme.setTheme(session.store.theme);

      if (this.sessionService.isSessionValid()) {
        this.items = session.cart;
        this.calculateTotal();
        this.setupStripe();
      }
      else {
        this.presentToast("Your cart has expired. Please re-scan items before checking out.", "Cart Expired", 8000);
        this.router.navigate(['']);
      }
    }
    else {
      this.items = [];
      this.events.publish('loading', true, "Session Error: Unable to retieve your cart.");
    }
  }

  ionViewDidEnter() {
    this.events.publish('loading', false);
    this.loading = false;
  }

  public calculateTotal() {
    var subtotal = 0;
    this.items.forEach(item => {
      subtotal += Number(item.price);
    });

    this.subtotal = subtotal;
    this.tax = subtotal * this.sessionService.getStore().tax;
    this.total = this.subtotal + this.tax;
  }

  public confirm() {
    this.payWithCard();
  }

  public cancel() {
    this.navController.setDirection("back", true, "back");
    this.location.back();
  }

  setupStripe() {
    // Prepare Stripe UI elements
    let elements = this.stripe.elements();
    var style = {
      base: {
        color: this.theme.getPrimaryColor(),
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    // Build and mount the credit card field
    this.stripeCard = elements.create('card', { style: style });
    this.stripeCard.mount('#card-element');

    // Add a change listener to apply error styles to credit card field
    this.stripeCard.addEventListener('change', event => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      }
      else {
        displayError.textContent = '';
      }
    });

    var _this = this;
    var totalCents = this.total * 100;

    // Setup the payment request button (Apple Pay, Google Pay, etc)
    var paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `${this.sessionService.getStore().name} Purchase`,
        amount: (totalCents <= 100) ? Math.floor(totalCents) : Math.ceil(totalCents)
      },
      requestPayerName: true,
      requestPayerEmail: true
    });

    // Build the payment request button styled correctly
    var prButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
      style: {
        paymentRequestButton: {
          type: 'default',
          theme: (this.theme.isDarkMode()) ? 'light' : 'dark',
          height: '40px'
        }
      }
    });

    // Check if payment requests can be made on the current device:
    // Apple Pay on iOS or Safari, Google Pay on Android, etc
    paymentRequest.canMakePayment().then(function (result) {
      if (result) {
        prButton.mount('#payment-request-button');

        // Register an event to capture the Stripe source token after payment request is completed.
        paymentRequest.on('token', function(ev) {
          _this.processing = true;
          _this.events.publish('loading', true, "Submitting payment...");

          var totalCents = _this.total * 100;
          var token = (ev.token) ? ev.token.id : ev.id;

          var payment: Payment = new Payment({
            amount: (totalCents <= 100) ? Math.floor(totalCents) : Math.ceil(totalCents),
            currency: "usd",
            sourceToken: token
          });

          // Make the actual charge request
          _this.paymentService.makePayment(payment).subscribe(
            (res) => {
              ev.complete('success');
              _this.events.publish('loading', true, "Generating receipt...");
              _this.router.navigate([`receipt`], { state: { paymentRecorded: true } });
              _this.processing = false;
            },
            (error) => {
              ev.complete('fail');
              console.log("Error submitting payment via payment request.");
              console.log(error);
              _this.alertService.toastError("There was a problem submitting your payment via payment request. Please try again.");
              _this.processing = false;
            });
        });
      }
      else {
        document.getElementById('payment-request-button').style.display = 'none';
        _this.canUseMobilePay = false;
      }
    });
  }

  private payWithCard() {
    this.processing = true;

    // Create a payment source and get a source token from Stripe
    this.stripe.createSource(this.stripeCard).then(result => {
      if (result.error) {
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
        this.processing = false;
        this.events.publish('loading', false);
      }
      else {
        this.events.publish('loading', true, "Submitting payment...");

        var totalCents = this.total * 100;
        var token = (result.source) ? result.source.id : result.id;

        var payment: Payment = new Payment({
          amount: (totalCents <= 100) ? Math.floor(totalCents) : Math.ceil(totalCents),
          currency: "usd",
          sourceToken: token
        });

        // Make the actual charge request
        this.paymentService.makePayment(payment).subscribe(
          (res) => {
            this.events.publish('loading', true, "Generating receipt...");
            this.router.navigate([`receipt`], { state: { paymentRecorded: true } });
            this.processing = false;
          },
          (error) => {
            console.log("Error submitting payment.");
            console.log(error);
            this.alertService.toastError("There was a problem submitting your payment. Please try again.");
            this.processing = false;
            this.events.publish('loading', false);
          });
      }
    });
  }

  private async presentToast(message: string, header: string = "Message", duration: number = 8000) {
    const toast = await this.toastController.create({
      header: header,
      message: message,
      duration: duration,
      position: "top",
      color: "dark",
      showCloseButton: true
    });
    toast.present();
  }
}
