import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@models/store.model';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    constructor(private httpClient: HttpClient) { }

    public getStoreById(id): Observable<Store> {
        return this.httpClient
            .get(`${environment.apiUrl}/store/${id}`)
            .pipe(
                map((res: any) => {
                    return new Store(res);
                })
            );
    }
}
