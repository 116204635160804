import { Component } from '@angular/core';
import { Product } from '@models/product.model';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from 'ng-etg-base';

import { ThemeService } from "@services/theme.service";
import { SessionService } from "@services/session.service";
import { ProductService } from '../../_services/product.service';
import { environment } from '@environments/environment';
import { AlertController, NavController } from '@ionic/angular';


@Component({
    selector: 'nfc-product-details',
    templateUrl: './product-details.page.html',
    styleUrls: ['./product-details.page.scss'],
})
export class ProductDetailsPage {

    public loading: boolean = true;
    public product: Product = new Product;
    public details: any = {};
    public styles: any = {};
    public cartExpirationTime: Number = environment.sessionDurationInSeconds;

    constructor(
        private router: Router,
        private navController: NavController,
        private theme: ThemeService,
        private alertService: AlertService,
        private sessionService: SessionService,
        private productService: ProductService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private location: Location,
        public alertController: AlertController
    ) { }

    ionViewWillEnter(){
        this.loading = true;
        this.handlePropertyDetailRequest();
    }

    ionViewDidEnter() {
        this.loading = false;
    }

    private handlePropertyDetailRequest(){
        this.route.paramMap.subscribe(params => {
            this.productService.getProductById(params.get('productId')).subscribe(
                (product: Product) => {
                    this.product = product;
                    this.product.inventory_id = params.get('inventoryId');
                    this.syncSession();
                },
                (error) => {
                  console.log("Error getting product data.");
                  console.log(error);
                  this.alertService.toastError("There was a problem retrieving the product's data. Please try again.");
                }
              );
        });
    }

    private syncSession() {
        var session = this.sessionService.loadSession();

        if (session != null) {
            this.theme.setTheme(session.store.theme);
            this.cartExpirationTime = session.getExpirationDuration();
        }
    }

    public backToCart(){
        this.navController.setDirection("back", true, "back");
        this.location.back();
    }

    public getSafeItemImage(imageUrl) {
        return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }
}