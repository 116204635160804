import { Theme } from '@models/theme.model';

export class Store {
  id: number = -1;
  name: string = "";
  subsidiary: string = "";
  tax: number = 0.00;
  theme: Theme = new Theme();

  constructor(obj?) {
    if (obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.subsidiary = obj.subsidiary;
        this.tax = obj.tax;
        this.theme = new Theme(obj.theme);
    }
  }
}