import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Payment } from '@models/payment.model';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(private httpClient: HttpClient) { }

    public makePayment(payment: Payment): Observable<any> {
        return this.httpClient
            .post(`${environment.apiUrl}/payment`, payment)
            .pipe(
                map((res: any) => {
                    return res;
                })
            );
    }
}
