import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileDropModule } from 'ngx-file-drop';
import { Stripe } from '@ionic-native/stripe/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ComponentsModule } from '@components/components.module';
import { DirectivesModule } from '@directives/directives.module';
import { CountdownModule } from 'ngx-countdown';

import { ReceiptPage } from '@pages/receipt/receipt.page';
import { CartPage } from '@pages/cart/cart.page';
import { ProductDetailsPage } from '@pages/product-details/product-details.page';
import { CheckoutPage } from '@pages/checkout/checkout.page';

@NgModule({
  declarations: [
    AppComponent,
    CartPage,
    ReceiptPage,
    ProductDetailsPage,
    CheckoutPage
  ],
  entryComponents: [

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({ hardwareBackButton: false }),
    AppRoutingModule,
    NgxDatatableModule,
    ScrollToModule,
    PdfViewerModule,
    FileDropModule,
    ComponentsModule,
    DirectivesModule,
    CountdownModule 
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Stripe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
