export class Theme {
    logo_url: string = "";
    primary_color: string = "";
    primary_color_contrast: string = "";
    secondary_color: string = "";
    secondary_color_contrast: string = "";
    dark_mode: boolean = false;

    constructor(obj?) {
        if (obj) {
            this.logo_url = obj.logo_url;
            this.primary_color = obj.primary_color;
            this.primary_color_contrast = obj.primary_color_contrast;
            this.secondary_color = obj.secondary_color;
            this.secondary_color_contrast = obj.secondary_color_contrast;
            this.dark_mode = obj.dark_mode;
        }
    }
}