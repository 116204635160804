import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';

import { ReceiptPage } from '@pages/receipt/receipt.page';
import { CartPage } from '@pages/cart/cart.page';
import { ProductDetailsPage } from '@pages/product-details/product-details.page';
import { CheckoutPage } from '@pages/checkout/checkout.page';

const routes: Routes = [
  // Public Routes
  { path: '', component: CartPage },
  { path: 'receipt', component: ReceiptPage },
  { path: 'details/:productId/:inventoryId', component: ProductDetailsPage },
  { path: 'checkout', component: CheckoutPage },
  
  // Default Redirect
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
