import { Component } from '@angular/core';

import { Platform, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ResolveEnd, ResolveStart } from '@angular/router';

import { AlertService, AlertPosition } from 'ng-etg-base';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public showSidebar: boolean;
  public loading: boolean;
  public loadingMessage: string = "";
  public currentRoute: string;

  public appPages = [
    {
      title: 'Cart',
      icon: 'ios-home',
      url: '/'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public events: Events,
    private alertService: AlertService
  ) {
    this.initializeApp();

    this.events.subscribe("showSidebar", (shouldShowSidebar) => {
      this.showSidebar = shouldShowSidebar;
    });

    this.events.subscribe("loading", (isLoading, message?) => {
      this.loading = isLoading;
      this.loadingMessage = message || "";
    });

    this.alertService.setGlobalPosition(AlertPosition.TOP);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ionViewWillUnload() {
    this.events.unsubscribe("showSidebar");
    this.events.unsubscribe("loading");
  }

  logout() {
    
  }
}
