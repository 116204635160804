import { Component, OnInit, OnDestroy } from '@angular/core';
import { Events } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivationEnd, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { ThemeService } from '@services/theme.service';
import { SessionService } from '@services/session.service';
import { Product } from '@models/product.model';
import { Session } from '@models/session.model';

@Component({
  selector: 'nfc-receipt',
  templateUrl: 'receipt.page.html',
  styleUrls: ['receipt.page.scss'],
})
export class ReceiptPage implements OnInit, OnDestroy {

  public loading: boolean = false;
  public paymentRecorded: boolean = false;
  private locationSub: Subscription;

  public items: Product[] = [];
  public subtotal: number = 0;
  public tax: number = 0;
  public total: number = 0;
  public today = "";

  constructor(
    private events: Events,
    private sanitizer: DomSanitizer,
    private router: Router,
    public theme: ThemeService,
    private sessionService: SessionService
  ) {
    this.locationSub = <Subscription>this.router.events.subscribe((event: Event) => {
      if (event instanceof ActivationEnd) {
        this.paymentRecorded = false;

        var routeState = this.router.getCurrentNavigation().extras.state;

        if (routeState && routeState.hasOwnProperty("paymentRecorded")) {
          this.paymentRecorded = routeState.paymentRecorded;
        }
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.locationSub.unsubscribe();
  }

  ionViewWillEnter() {
    if (!this.paymentRecorded) {
      this.router.navigate(['']);
    }

    this.today = moment().format("MMMM Do, YYYY");

    // After checkout, invalidate the session and retrieve the cart for display on the receipt
    var session: Session = this.sessionService.loadSession();
    session.valid = false;
    this.sessionService.invalidateSession();

    if (session != null) {
      this.theme.setTheme(session.store.theme);
      this.items = session.cart;
      this.calculateTotal();
    }
    else {
      this.items = [];
      this.events.publish('loading', true, "Session Error: Unable to retieve your cart.");
    }
  }

  ionViewDidEnter() {
    this.events.publish('loading', false);
  }

  public calculateTotal() {
    var subtotal = 0;
    this.items.forEach(item => {
      subtotal += Number(item.price);
    });

    this.subtotal = subtotal;
    this.tax = subtotal * this.sessionService.getStore().tax;
    this.total = this.subtotal + this.tax;
  }

  public getSafeItemImage(imageUrl) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
  }
}
