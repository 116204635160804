import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { Session } from '@models/session.model';
import { Product } from '@models/product.model';
import { Store } from '@models/store.model';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    constructor() { }

    private session: Session = null;
    private storage = localStorage;
    private storageKey = "dn-nfc-session";

    public getSession() {
        return this.session;
    }

    public newSession(store: Store) {
        this.session = new Session({store: store});
        this.session.refreshSession();
        this.saveSession();
    }

    public clearSession() {
        this.session = null;
    }

    public invalidateSession() {
        if (!this.session) return null;

        this.session.valid = false;
        this.saveSession();
    }

    public validateSession() {
        if (!this.session) return null;

        this.session.valid = true;
        this.saveSession();
    }

    public saveSession() {
        this.storage.setItem(this.storageKey, JSON.stringify(this.session));
    }

    public loadSession(): Session {
        var storedSession = this.storage.getItem(this.storageKey);

        if (storedSession) {
            this.session = new Session(JSON.parse(storedSession));
        }
        else {
            this.session = null;
        }

        return this.session;
    }

    public getStore(): Store {
        if (!this.session) return null;

        return this.session.store;
    }

    public doesStoreMatchSession(store_id) {
        if (!this.session) return false;

        return this.session.store.id == store_id;
    }

    public getCart(): Product[] {
        if (!this.session) return null;

        return this.session.cart;
    }

    public addToCart(product: Product): Product[] {
        if (!this.session) return null;

        this.session.cart.push(product);
        this.session.refreshSession();
        this.saveSession();
        return this.session.cart;
    }

    public removeFromCart(product: Product): Product[] {
        if (!this.session) return null;

        var itemIndex = this.session.cart.findIndex((p: Product) => p.id == product.id && p.inventory_id == product.inventory_id);

        if (itemIndex > -1) {
            this.session.cart.splice(itemIndex, 1);
        }

        this.session.refreshSession();
        this.saveSession();
        return this.session.cart;
    }

    public clearCart() {
        if (!this.session) return null;

        this.session.cart = [];
        this.saveSession();
    }

    public isProductTagInCart(product_id, inventory_id): boolean {
        if (!this.session) return false;

        return this.session.cart.some(p => p.id == product_id && p.inventory_id == inventory_id);
    }

    public isSessionValid(): boolean {
        if (!this.session) return false;

        return this.session.valid;
    }

    public getExpirationTime() {
        if (!this.session) return null;

        return this.session.expiresAt;
    }

    public getExpirationDuration() {
        if (!this.session) return null;

        return this.session.getExpirationDuration();
    }

    public shouldSessionExpire(): boolean {
        if (!this.session) return true;

        return this.session.getExpirationDuration() <= 0;
    }

    public expireSession() {
        this.invalidateSession();
    }
}
