import { Product } from '@models/product.model';
import { Store } from '@models/store.model';
import { environment } from '@environments/environment';

import * as moment from 'moment';

export class Session {
  store: Store = new Store();
  cart: Product[] = [];
  expiresAt: number;
  valid: boolean = true;

  constructor(obj?) {
    if (obj) {
      this.store = new Store(obj.store);
      this.cart = (obj.cart) ? obj.cart.map((p) => { return new Product(p); }) : [];
      this.expiresAt = obj.expiresAt;
      this.valid = (obj.valid != undefined) ? obj.valid : true;
    }
  }

  public refreshSession() {
    var now = moment();
    var addedTime = now.add(environment.sessionDurationInSeconds, 's');
    this.expiresAt = addedTime.unix();
  }

  public getExpirationDuration(): number {
    var now = moment();
    var expireTime = moment.unix(this.expiresAt);
    var duration = moment.duration(expireTime.diff(now));
    return duration.asSeconds();
  }
}