import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '@models/product.model';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private httpClient: HttpClient) { }

    public getProductById(id): Observable<Product> {
        return this.httpClient
            .get(`${environment.apiUrl}/product/${id}`)
            .pipe(
                map((res: any) => {
                    return new Product(res);
                })
            );
    }
}
