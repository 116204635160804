import { Injectable } from '@angular/core';

import { Theme } from '@models/theme.model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private theme: Theme;

  constructor() { }

   public getTheme(): Theme {
     return this.theme;
   }

   public setTheme(theme: Theme) {
    this.theme = theme;
   }

   public getLogoUrl() {
     if (!this.theme) return "";

     return this.theme.logo_url;
   }

  public getPrimaryColor() {
    if (!this.theme) return "";

    return this.theme.primary_color;
  }

  public getPrimaryContrastColor() {
    if (!this.theme) return "";

    return this.theme.primary_color_contrast;
  }

  public getSecondaryColor() {
    if (!this.theme) return "";

    return this.theme.secondary_color;
  }

  public getSecondaryContrastColor() {
    if (!this.theme) return "";

    return this.theme.secondary_color_contrast;
  }

  public isDarkMode() {
    if (!this.theme) return false;

    return this.theme.dark_mode;
  }
}
