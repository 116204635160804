import { Component } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd } from "@angular/router";
import { Events, ModalController, ToastController, AlertController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';

import { AlertService } from 'ng-etg-base';

import { ThemeService } from '@services/theme.service';
import { StoreService } from '@services/store.service';
import { ProductService } from '@services/product.service';
import { SessionService } from '@services/session.service';

import { Store } from '@models/store.model';
import { Product } from '@models/product.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'etg-cart',
  templateUrl: 'cart.page.html',
  styleUrls: ['cart.page.scss'],
})
export class CartPage {

  public loading: boolean = false;
  public deletingItem: boolean = false;
  public cartLocked: boolean = false;

  public cartItems: Product[] = [];
  public cartTotal: Number = 0.00;
  public cartExpirationTime: Number = environment.sessionDurationInSeconds;
  private locationSub: Subscription;
  private fromPopState: boolean = false;

  constructor(
    private events: Events,
    public ModalController: ModalController,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    public theme: ThemeService,
    private storeService: StoreService,
    private productService: ProductService,
    public sessionService: SessionService,
    public toastController: ToastController,
    public alertController: AlertController
  ) {
    // Latch onto router events to handle page state differences after navigation.
    // In this case, when navigating back from the details page, we want to avoid processing the tag again.
    this.locationSub = <Subscription>this.router.events.subscribe((event: Event) => {
      // At the start of navigation, check if the trigger was from a popstate (back button)
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger == "popstate") {
          this.fromPopState = true;
        }
        else {
          this.fromPopState = false;
        }
      }

      // At the end of navigation, if the routing ends up on the cart page and popstate is false, we can process the tag as normal.
      // Otherwise simply sync the session and don't process the tag because we came from the back button.
      if (event instanceof NavigationEnd) {
        if (event.url.substring(0, event.url.indexOf("?")) == "/" && this.fromPopState == false) {
          this.handleTagScan();
        }
        else if (event.url.substring(0, event.url.indexOf("?")) == "/" && this.fromPopState == true) {
          this.syncSession();
          this.events.publish('loading', false);
        }
        else if (event.url == "/") {
          var loadSuccessful = this.attemptLoadSession();

          if (loadSuccessful) {
            if (!this.sessionService.isSessionValid()) {
              this.sessionService.clearCart();
              this.syncSession();
              this.cartExpirationTime = 0;
            }
            else {
              this.syncSession();
            }
          }
          else {
            this.events.publish('loading', true, "No Session: Please scan a product.");
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.locationSub.unsubscribe();
  }

  /*
    Checks the routes query parameters for the needed values to lookup store and product
    information so an item can be added to the cart.
  */
  private handleTagScan() {
    this.route.queryParamMap.subscribe(queryParams => {
      this.events.publish('loading', true, "Looking up item...");

      var storeId = queryParams.get("storeId");
      var productId = queryParams.get("productId");
      var inventoryId = queryParams.get("inventoryId");

      // Since we are subscribing to the query parameters, if we are coming from a popstate, ignore handling the tag
      if (this.fromPopState == true) return;

      // If the store ID is present in the query params, look it up and continue
      if (storeId) {

        // Try to load the session
        var loadSuccessful = this.attemptLoadSession();

        // Check if there is already a valid session for the incoming store ID, if so, skip store lookup and continue to product lookup
        if (loadSuccessful && this.sessionService.isSessionValid() && this.sessionService.doesStoreMatchSession(storeId)) {
          this.syncSession();
          this.handleTagProduct(productId, inventoryId);
        }

        // If there is no valid session, or the stores do not match, create a new session
        else {
          this.sessionService.clearSession();

          this.storeService.getStoreById(storeId).subscribe(
            (store: Store) => {
              this.sessionService.newSession(store);
              this.syncSession();
              this.handleTagProduct(productId, inventoryId);
            },
            (error) => {
              console.log("Error getting store data.");
              console.log(error);
              this.alertService.toastError("There was a problem retrieving the store's data. Please try again.");
              this.events.publish('loading', true, "Tag Error: Please try again.");
            }
          );
        }
      }

      // If the store ID is NOT present in the query params, we cannot process the tag. Attempt to retrieve a saved session.
      // Otherwise, display a message informing the user to scan a tag.
      else {
        // Try to load the session
        var loadSuccessful = this.attemptLoadSession();

        if (loadSuccessful) {
          this.syncSession();
          this.events.publish('loading', false);
        }
        else {
          this.events.publish('loading', true, "Tag Error: Scan another product.");
        }
      }
    });
  }

  private handleTagProduct(productId, inventoryId) {
    // If the product ID and inventory IDs are present in the query params, look up the product and add it to the cart
    if (productId && inventoryId) {

      // Check if there is already an item in the cart with the unique product/inventory ID combo.
      // If so, don't add the item and display a message saying the item is already in the cart.
      if (this.sessionService.isProductTagInCart(productId, inventoryId)) {
        this.presentToast("Please scan a different tag to add an item to the cart.", "Item Already In Cart");
        this.events.publish('loading', false);
      }

      // If the item is not already in the cart, look it up and add it to the cart
      else {
        this.productService.getProductById(productId).subscribe(
          (product: Product) => {
            product.inventory_id = inventoryId;
            this.addProductToCart(product);
          },
          (error) => {
            console.log("Error getting product data.");
            console.log(error);
            this.alertService.toastError("There was a problem retrieving the product's data. Please try again.");
            this.events.publish('loading', true, "Tag Error: Please try again.");
          }
        );
      }
    }

    // If the product ID and inventory IDs are NOT present in the query params, we cannot process the tag.
    // Display a message saying there is a problem with the tag and attempt to load an existing cart.
    else {
      // Try to load the session
      var loadSuccessful = this.attemptLoadSession();

      if (loadSuccessful) {
        this.syncSession();
        this.events.publish('loading', false);
        this.alertService.toastError("There is a problem with the tag you have scanned, please try again.");
      }
      else {
        this.events.publish('loading', true, "Tag Error: Scan another product.");
      }
    }
  }

  private attemptLoadSession(): boolean {
    var session = this.sessionService.loadSession();
    return session != null;
  }

  private syncSession() {
    var session = this.sessionService.getSession();

    if (session != null) {
      this.theme.setTheme(session.store.theme);

      this.cartItems = session.cart;
      this.cartExpirationTime = session.getExpirationDuration();
      this.calculateCartTotal();

      if (session.valid) {
        this.cartLocked = false;
      }
    }
  }

  public expireSession() {
    // If already invalid, don't trigger twice. This is to avoid the countdown triggering again when returning with an expired cart.
    if (!this.sessionService.isSessionValid()) return;

    this.cartLocked = true;
    this.sessionService.expireSession();
    this.sessionService.clearCart();
    this.cartItems = [];
    this.cartTotal = 0.00;
    this.cartExpirationTime = 0;
    this.presentExpiredCartAlert();
  }

  async presentExpiredCartAlert() {
    const alert = await this.alertController.create({
      header: 'Cart Expired',
      message: 'You cart has expired, the items will be removed from your cart. Scan an item to start a new cart.',
      backdropDismiss: false,
      cssClass: 'nfc-alert',
      buttons: [
        {
          text: 'Okay',
          role: 'ok',
          handler: () => { this.router.navigate(['']); }
        }
      ]
    });

    await alert.present();
  }

  public addProductToCart(product: Product) {
    this.sessionService.addToCart(product)

    this.syncSession();
    this.calculateCartTotal();
    this.events.publish('loading', false);
  }

  public removeProductFromCart(product: Product) {
    this.deletingItem = true;
    
    this.sessionService.removeFromCart(product);
    
    this.syncSession();
    this.calculateCartTotal();
    this.deletingItem = false;
  }

  private calculateCartTotal() {
    var total = 0;
    this.cartItems.forEach(item => {
      total += Number(item.price);
    });
    this.cartTotal = total;
  }

  public checkout() {
    this.router.navigate([`checkout`], { state: { applePay: false } });
  }

  public getSafeItemImage(imageUrl) {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
  }

  private async presentToast(message: string, header: string = "Message", duration: number = 8000) {
    const toast = await this.toastController.create({
      header: header,
      message: message,
      duration: duration,
      position: "top",
      color: "dark",
      showCloseButton: true
    });
    toast.present();
  }

  public showProductDetails(product){
    this.router.navigate([`details/${product.id}/${product.inventory_id}`]);    
  }
}
